<template>
  <div :class="needPay?'needPay':''" @click="jumpPay">
    <div v-html="html"></div>
  </div>
</template>

<script>
import request from "../api/index";
export default {
  // props: ["id"],
  data() {
    return {
      cId: '',
      html: '',
      needPay: false
    }
  },
  created() {
    this.cId = this.$route.query.cId;
    const success = (res) => {
      this.html = res;
      if(this.isNeedPay()){
        this.needPay = true;
      }
    }
    request(
      `naskpackage/getart?cId=${this.cId}`,
      success
    );
  },
  methods:{
    jumpPay(){
      if(this.isNeedPay()){
        this.$router.push({ path: 'pay', query: { cId:this.cId }})
      }
    },
    // 判断是否付费
    isNeedPay(){
      // const cid = this.cId;
      // const sig = cid.substring(cid.length-1);
      // if(Number(sig)%2 !== 0 || Number(sig) === 2 || Number(sig) === 6 ){
      //   return true;
      // }
      // return false;
      return true;
    }
  }
}
</script>

<style>
.hdf-nav-custom-1001{display: none;}
.needPay{
  max-height: 200px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.needPay::after{
  content: '付费10元查看完整内容';
  color: #f80;
  text-align: center;
  font-size: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  line-height: 50px;
  padding-top: 50px;
  background-image: -webkit-gradient(linear,top, bottom,from(rgba(255,255,255,0)),color-stop(70%, #fff));
  background-image: linear-gradient(-180deg,rgba(255,255,255,0) 0%,#fff 70%);
}
</style>
